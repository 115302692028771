import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import MyButton from "../UI/button/MyButton";
import MySelect from "../UI/select/MySelect";
import MyInputCheckbox from "../UI/input/MyInputCheckbox";
import MyLoader from "../UI/loader/MyLoader";
import AuthContext from "../../context/AuthContext";

const FIleQueue = ({ handleError }) => {
  const [fileList, setFileList] = useState([]);
  const [chosenFile, setChosenFile] = useState();
  const [preset, setPreset] = useState("");

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
} = useContext(AuthContext);

  const getFileList = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${API_URL}/files/`,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      console.log(response);
      setFileList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await getFileList();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data[0]) {
                setGeneralError([e.response.status, e.response.data[0]]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        setLoading(false);
    }
  };

  useEffect(() => {
    getFileList();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${API_URL}/queue/`,
        data: {
          file: chosenFile,
        },
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      console.log(response);
    } catch (e) {
      console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await handleSubmit();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data[0]) {
                setGeneralError([e.response.status, e.response.data[0]]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        setLoading(false);
    }
  };

  return (
    <div className="application-useful-block">
      <h1>Выбор файла для анализа</h1>
      {loading ? (
        <MyLoader />
      ) : (
        <div className="file-queue-form">
          <p>
            Ваш файл будет поставлен в очередь. Анализ будет произведен согласно
            очереди, Вы получите уведомление о его завершении.
          </p>
          <MySelect
            value={chosenFile}
            onChange={(e) => setChosenFile(e)}
            defaultValue={"Выберите файл"}
            options={fileList}
          />
          <MyButton type="submit" onClick={handleSubmit}>
            Отправить
          </MyButton>
        </div>
      )}
    </div>
  );
};

export default FIleQueue;
