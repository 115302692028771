import React from "react";
import MyInputText from "../UI/input/MyInputText";
import { useState, useEffect } from "react";

const FileSearch = ({ setSearchString }) => {
  
    const handleSearch = (e) => {
        setSearchString(e.target.value)
    }
    
    return (
    <div className="file-search-input">
      <MyInputText type="text" placeholder="Поиск по файлам" onChange={handleSearch}/>
      <i class="material-icons search-icon">search</i>
    </div>
  );
};

export default FileSearch;
