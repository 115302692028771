import React from "react";
import { useState, useContext } from "react";
import { API_URL } from "../../config";
import MyButton from "../UI/button/MyButton";
import MyInputText from "../UI/input/MyInputText";
import MyInputFile from "../UI/input/MyInputFile";
import MyLoader from "../UI/loader/MyLoader";
import AuthContext from "../../context/AuthContext";

const FileUpload = ({ handleError, setComponentKey }) => {

  var xhr = new XMLHttpRequest();

  const [file, setFile] = useState();
  const progressRef = React.useRef();
  const [description, setDescription] = useState("");

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
} = useContext(AuthContext);

  console.log(file);
  function handleFileChange(event) {
    setFile(event.target.files[0]);
  }

  const handleDescChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async () => {
    var xhr = new XMLHttpRequest();
    var formData = new FormData();

    xhr.upload.onprogress = function(event) {
      console.log(event.loaded + ' / ' + event.total);
      var percent = (event.loaded / event.total) * 100;
      progressRef.current.value = Math.round(percent);
    }
    xhr.onload = xhr.onerror = async function() {
      if (this.status == 201) {
        console.log("success");
        setComponentKey(Math.random());

      } else {
        console.log("error " + this.status);
        if (this.status === 401 && !isRefreshingToken) {
          setIsRefreshingToken(true); // Set flag to prevent recursion
          await updateToken();
          setIsRefreshingToken(false); // Reset flag after refresh
          await handleSubmit();
      }
    }
    };

    // formData.append("user", userId);
    formData.append("file", file);
    formData.append("description", description);
    xhr.open("POST", `${API_URL}/files/`)
    xhr.setRequestHeader("Accept-Language", "ru");
    xhr.setRequestHeader("Authorization", `Bearer ${authTokens.access_token}`);
    xhr.send(formData)
  };

  return (
    <div className="application-useful-block">
      <h1>Загрузка видеофайла для анализа</h1>
      <div className="file-upload-form">
        <MyInputFile type="file" onChange={handleFileChange} file={file} />
        <label>
        Прогресс загрузки: <progress className="progress" ref={progressRef} value="0" max="100" />
        </label>
        <p>
          Комментарии к загружаемому файлу (место, время, качество съёмки и
          пр.):
        </p>
        <MyInputText type="text" onChange={handleDescChange} />
        <MyButton type="submit" onClick={handleSubmit}>
          Загрузить
        </MyButton>
      </div>
    </div>
  );
};

export default FileUpload;
