import { Line } from "react-chartjs-2";
export const TimeLineChart = ({ chartData }) => {
  return (
    <div className="chart-container">
      <h2 style={{ textAlign: "center" }}>Временная диаграмма</h2>
      <Line
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Изменение каждой эмоции в течении урока",
            },
            legend: {
              
            },
          },
        }}
      />
    </div>
  );
};
