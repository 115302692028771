import React from 'react';
import classes from './MySelect.module.css'

const MySelect = ({options, defaultValue, value, onChange}) => {
    return (
        <select 
            value={value}
            onChange={event => onChange(event.target.value)}
            className={classes.mySelect}
        >
            <option value="">{defaultValue}</option>
            {options.map(option => 
                <option value={option.id} key={option.id}>
                    {option.filename}
                </option>    
            )}
        </select>
    );
}

export default MySelect;
