import React, { useState, useEffect, useContext } from "react";
import Navbar from "../components/Navbar";
import MyToast from "../components/UI/toast/MyToast";
import { CSSTransition } from "react-transition-group";
import FileChosing from "../components/StatsPage/FileChosing";
import ChartChosing from "../components/StatsPage/ChartChosing";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import AuthContext from "../context/AuthContext";
import { BarChart } from "../components/StatsPage/BarChart";
import { RadarChart } from "../components/StatsPage/RadarChart";
import PieChart from "../components/StatsPage/PieChart";

Chart.register(CategoryScale);

const Stats = () => {
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState();

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
} = useContext(AuthContext);

  const handleError = (t_error) => {
    setError(t_error);
  };

  const handleData = (data) => {
    setChartData(data)
  }

  return (
    <div className="height-need">
      <MyToast error={generalError} closeAlert={closeAlert} />
      <Navbar />
      <div className="main">
        <div className="two-sides-flex sides-flex-stats">
          <div className="stats-settings-block">
            <FileChosing handleData={handleData} handleError={handleError}/>
          </div>
          <div className="stats-charts-block">
            {/* <FileList handleError={handleError} /> */}
            {chartData && <ChartChosing key={chartData} cleanData={chartData}></ChartChosing>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
