function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

// export const Data = [
//   {
//     id: 1,
//     emotion: "anger",
//     value: 87654,
//   },
//   {
//     id: 2,
//     emotion: "calmness",
//     value: 567000,
//   },
//   {
//     id: 3,
//     emotion: "joy",
//     value: 219765,
//   },
//   {
//     id: 4,
//     emotion: "wow",
//     value: 56100,
//   },
// ];

// export const Data = {
//   "data": {
//       "anger": 0.0,
//       "calmness": 35.71,
//       "joy": 64.29,
//       "wow": 0.0
//   }
// }

export const Data = {
  "data": {
      "anger": 0,
      "calmness": 0,
      "joy": 0,
      "wow": 0
  }
}



export const DataTime = {
  "data": [
      {
          "point": 1,
          "anger": 0,
          "calmness": 0,
          "joy": 0,
          "wow": 0,
          "user": "",
          "file": ""
      },
  ]
}
console.log(Object.keys(Data.data))
// export const DataTime = [
//   {
//     id: 1,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 2,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 3,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 4,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 5,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 6,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 7,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 8,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
//   {
//     id: 9,
//     value_anger: getRandomArbitrary(5, 12),
//     value_calmness: getRandomArbitrary(40, 61),
//     value_joy: getRandomArbitrary(19, 23),
//     value_wow: getRandomArbitrary(9, 16),
//   },
// ];
