import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import MyLoader from "../UI/loader/MyLoader";
import OneFileInList from "./OneFileInList";
import FilePagination from "./FilePagination";
import FileSearch from "./FileSearch";
import AuthContext from "../../context/AuthContext";

const FileList = ({ handleError }) => {
  const [fileList, setFileList] = useState([]);
  const [originFileList, setOriginFileList] = useState([]);

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
} = useContext(AuthContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [filesPerPage] = useState(3);
  const lastFileIndex = currentPage * filesPerPage;
  const firstFileIndex = lastFileIndex - filesPerPage;
  const currentFile = fileList.slice(firstFileIndex, lastFileIndex);

  const [searchString, setSearchString] = useState("");

  const createSearchedList = () => {
    if (searchString.length > 0) {
      const newFileList = originFileList.filter((item) => {
        return (
          item.description.toLowerCase().includes(searchString.toLowerCase()) ||
          item.filename.toLowerCase().includes(searchString.toLowerCase())
        );
      });
      setFileList(newFileList);
      setCurrentPage(1);
    } else {
      setFileList(originFileList);
      setCurrentPage(1);
    }
  };

  console.log(searchString);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const nextPage = () => {
    if (currentPage !== Math.ceil(fileList.length / filesPerPage)) {
      setCurrentPage((prev) => prev + 1);
    } else {
      setCurrentPage(1);
    }
  };
  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
    } else {
      setCurrentPage(Math.ceil(fileList.length / filesPerPage));
    }
  };

  const getFileList = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${API_URL}/files/`,
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      console.log(response);
      setFileList(response.data);
      setOriginFileList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await getFileList();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data[0]) {
                setGeneralError([e.response.status, e.response.data[0]]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        setLoading(false);
    }
  };

  useEffect(() => {
    getFileList();
  }, []);

  useEffect(() => {
    createSearchedList();
  }, [searchString]);

  return (
    <>
      <div className="application-useful-block">
        {loading ? (
          <MyLoader />
        ) : (
          <div className="file-list-body">
            <h1>Список загруженных файлов</h1>

            <div className="file-list-block">
              <FileSearch setSearchString={setSearchString} />
              {currentFile.map((item) => (
                <OneFileInList
                  filename={item.filename}
                  description={item.description}
                  status={item.status}
                  key={item.pk}
                />
              ))}
            </div>
            <FilePagination
              filesPerPage={filesPerPage}
              totalFiles={fileList.length}
              paginator={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
      {/* <button onClick={() => handleError(['405', 'btuivnercod'])}>нажать</button> */}
    </>
  );
};

export default FileList;
