import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Profile from "./pages/Profile";
import Video from "./pages/Video";
import Stats from "./pages/Stats";
import NotFound from "./pages/NotFound";
import ProfilePage from "./pages/ProfilePage";
import LoginGet from "./pages/LoginGet";
import LoginCheck from "./pages/LoginCheck";
import Register from "./pages/Register";
import EmailVerify from "./pages/EmailVerify";
import EmailConfirm from "./pages/EmailConfirm";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/main" element={<PrivateRoute><Main /></PrivateRoute>} />
            <Route path="/video" element={<PrivateRoute><Video /></PrivateRoute>} />
            <Route path="/stats" element={<PrivateRoute><Stats /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
            <Route path="/login" element={<LoginGet />} />
            <Route path="/check" element={<LoginCheck />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/verify" element={<EmailVerify />} />
            <Route path="/register/confirm" element={<EmailConfirm />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
