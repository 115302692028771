import React, { useEffect, useState } from "react";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { BarChart } from "../../components/StatsPage/BarChart";
import { RadarChart } from "../../components/StatsPage/RadarChart";
import PieChart from "../../components/StatsPage/PieChart";
import MyButton from "../UI/button/MyButton";
import { TimeLineChart } from "./TimeLineChart";

Chart.register(CategoryScale);

const ChartChosing = ({ cleanData }) => {
  console.log(cleanData[2])
  const [chosenChart, setChosenChart] = useState("Timeline");
  const dataArray = Object.values(cleanData[1])
  const [chartData, setChartData] = useState({
    labels: Object.keys(cleanData[1]),
    datasets: [
      {
        label: "Кол-во",
        data: dataArray,
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 1,
      },
    ],
  });

  const [chartData2, setChartData2] = useState({
    labels: cleanData[2].map((data) => data.point),
    datasets: [
      {
        label: "Anger",
        data: cleanData[2].map((data) => data.anger),
        borderColor: "blue",
        borderWidth: 2,
      },
      {
        label: "Calmness",
        data: cleanData[2].map((data) => data.calmness),
        borderColor: "red",
        borderWidth: 2,
      },
      {
        label: "Joy",
        data: cleanData[2].map((data) => data.joy),
        borderColor: "orange",
        borderWidth: 2,
      },
      {
        label: "Wow",
        data: cleanData[2].map((data) => data.wow),
        borderColor: "green",
        borderWidth: 2,
      },
    ],
  });

  useEffect(() => {
    setChartData({
      labels: Object.keys(cleanData[1]).map((data) => data),
      datasets: [
        {
          label: "Кол-во",
          data: dataArray,
          backgroundColor: [
            "rgba(75,192,192,1)",
            "#ecf0f1",
            "#50AF95",
            "#f3ba2f",
            "#2a71d0",
          ],
          borderColor: "black",
          borderWidth: 1,
        },
      ],
    });

    setChartData2({
      labels: cleanData[2].map((data) => data.point),
      datasets: [
        {
          label: "Anger",
          data: cleanData[2].map((data) => data.anger),
          borderColor: "blue",
          borderWidth: 2,
        },
        {
          label: "Calmness",
          data: cleanData[2].map((data) => data.calmness),
          borderColor: "red",
          borderWidth: 2,
        },
        {
          label: "Joy",
          data: cleanData[2].map((data) => data.joy),
          borderColor: "orange",
          borderWidth: 2,
        },
        {
          label: "Wow",
          data: cleanData[2].map((data) => data.wow),
          borderColor: "green",
          borderWidth: 2,
        },
      ],
    });
  }, [cleanData]);

  return (
    <div className="application-useful-block">
      <h1>Выберите тип графика</h1>
      <div className="charts-buttons-row">
        <MyButton onClick={() => setChosenChart("Bar")}>Столбчатая</MyButton>
        <MyButton onClick={() => setChosenChart("Pie")}>Круговая</MyButton>
        <MyButton onClick={() => setChosenChart("Radar")}>Радарная</MyButton>
        <MyButton onClick={() => setChosenChart("Timeline")}>Таймлайн</MyButton>
      </div>

      <div className="charts-block">
        {chosenChart === "Bar" && <BarChart chartData={chartData} />}
        {chosenChart === "Pie" && <PieChart chartData={chartData} />}
        {chosenChart === "Radar" && <RadarChart chartData={chartData} />}
        {chosenChart === "Timeline" && <TimeLineChart chartData={chartData2} />}
      </div>
    </div>
  );
};

export default ChartChosing;
