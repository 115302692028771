import React from "react";

const OneFileInList = ({filename, description, status}) => {
  return (
    <div className="one-file-body">
      <table className="one-file-table">
        <tr className="table-row">
          <th className="row-name">Имя файла:</th>
          <th className="row-value">{filename}</th>
        </tr>
        <tr className="table-row">
          <td className="row-name">Описание:</td>
          <td className="row-value">{description}</td>
        </tr>
        <tr>
          <td className="row-name">Статус:</td>
          <td className="row-value">{status}</td>
        </tr>
      </table>
    </div>
  );
};

export default OneFileInList;
