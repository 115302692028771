import React from "react";

const FilePagination = ({
  filesPerPage,
  totalFiles,
  paginator,
  nextPage,
  prevPage,
  currentPage,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalFiles / filesPerPage); i++) {
    pageNumbers.push(i);
  }
  console.log(pageNumbers);

  const returnPages = (number) => {
    if (number === currentPage) {
      return <div
        key={number}
        className="page-link page-link-current"
        onClick={() => paginator(number)}
      >
        {number}
      </div>;
    } else {
       return <div key={number} className="page-link" onClick={() => paginator(number)}>
        {number}
      </div>;
    }
  };

  return (
    <div>
      <ul className="pagination">
        <div className="page-link" onClick={prevPage}>
          {"<"}
        </div>
        {pageNumbers.map(returnPages)}
        <div className="page-link" onClick={nextPage}>
          {">"}
        </div>
      </ul>
    </div>
  );
};

export default FilePagination;
