import React, { useContext, useState } from "react";
import MyInputText from "../UI/input/MyInputText";
import MyButton from "../UI/button/MyButton";
import { useNavigate } from "react-router-dom";
import { API_AUTH_URL } from "../../config";
import axios from "axios";
import AuthContext from "../../context/AuthContext";

const LoginGetForm = () => {
    const [entered, setEntered] = useState(null);
    const [enteredError, setEnteredError] = useState(null);
    const navigate = useNavigate();
    const { setGeneralError } = useContext(AuthContext);

    const handleChange = (e) => {
        setEntered(e.target.value);
        setEnteredError(null);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        axios({
            method: "GET",
            url: `${API_AUTH_URL}/login/`,
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
            },
            params: {
                destination: entered,
            },
        })
            .then((res) => {
                if (res.data.IntegrityError === true) {
                    alert("IntegrityError");
                } else {
                    navigate("/check", { replace: true });
                }
            })
            .catch((e) => {
                console.log(e);
                if (e.response.data.destination) {
                    setEnteredError(e.response.data.destination);
                } else if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data === "") {
                    setEnteredError("Поле не может быть пустым");
                } else if (e.response.data.message) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.message,
                    ]);
                } else if (e.response.data[0]) {
                    setGeneralError([e.response.status, e.response.data[0]]);
                } else {
                    setGeneralError(["", "Ошибка сервера"]);
                }
            });
    };

    localStorage.setItem("number", entered);

    return (
        <div>
            <div>
                <div className="login-form-block">
                    <div className="enter-name-block">
                        Введите Почту или Телефон:
                        <MyInputText
                            name="login"
                            onChange={(event) => handleChange(event)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    {enteredError ? (
                        <div className="errors-message-class">
                            {enteredError}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <MyButton onClick={handleSubmit} margin_top="1rem">Войти</MyButton>
            </div>
        </div>
    );
};

export default LoginGetForm;
