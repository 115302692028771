import React, { useState, useContext } from "react";
import Navbar from "../components/Navbar";
import FileUpload from "../components/VideoPage/FileUpload";
import FileList from "../components/VideoPage/FileList";
import FIleQueue from "../components/VideoPage/FIleQueue";
import MyToast from "../components/UI/toast/MyToast";
import AuthContext from "../context/AuthContext";
import { CSSTransition } from "react-transition-group";

const Video = () => {
  const [componentKey, setComponentKey] = useState(1);
  const [error, setError] = useState(null);

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
} = useContext(AuthContext);

  const handleError = (t_error) => {
    setError(t_error);
  };

  return (
    <>
      <div className="height-need">
        <MyToast error={generalError} closeAlert={closeAlert} />
        <Navbar />
        <div className="main">
          <div className="two-sides-flex">
            <div className="vertical-even-two-flex">
              <FileUpload handleError={handleError} setComponentKey={setComponentKey} key={componentKey}/>
              <FIleQueue handleError={handleError} />
            </div>
            <div className="vertical-even-one-flex">
              <FileList handleError={handleError} key={componentKey}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
