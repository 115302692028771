import React from "react";
import Navbar from "../components/Navbar";

const Main = () => {
  return (
    <div>
      <Navbar />
      <div className="main">Контент</div>
    </div>
  );
};

export default Main;
