import React, { useEffect, useRef, useState } from "react";
import homeImg from "../assets/images/home.png";
import logoutImg from "../assets/images/logout.png";
import playImg from "../assets/images/play.png";
import profileImg from "../assets/images/profile.png";
import settingsImg from "../assets/images/settings.png";
import statsImg from "../assets/images/stats.png";
import { Link } from "react-router-dom";

const Navbar = () => {

  const iconRef = useRef(null)
  const menuRef = useRef(null)

  const burgerClick = () => {
    if (iconRef) {
      const menu = menuRef.current;
      menu.classList.toggle("active")
      const icon = iconRef.current;
      icon.classList.toggle("active")
    }
  }

  
  return (
    <div>
      <div className="header">
        <div className="header_burger" onClick={burgerClick} ref={iconRef}>
          <span></span>
        </div>
        <nav className="sidenav" ref={menuRef}>
          <Link to="/profile">
            <div className="pic-logout">
              <img src={profileImg} />
            </div>
          </Link>
          <div className="menu-block">
            {/* <Link to="/main">
              <div className="pic-home">
                <img src={homeImg} />
              </div>
            </Link> */}
            <Link to="/video">
              <div className="pic-play">
                <img src={playImg} />
              </div>
            </Link>
            <Link to="/stats">
              <div className="pic-stats">
                <img src={statsImg} />
              </div>
            </Link>
          </div>
          <Link to="/main">
            <div className="pic-logout">
              <img src={logoutImg} />
            </div>
          </Link>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
