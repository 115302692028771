import React, { useContext, useEffect, useState, useRef } from "react";
import Navbar from "../components/Navbar";
import MyInputText from "../components/UI/input/MyInputText";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { API_AUTH_URL, ORGANIZATION_NAME } from "../config";
import axios from "axios";
import MyLoader from "../components/UI/loader/MyLoader";
import MyButton from "../components/UI/button/MyButton";
import MyToast from "../components/UI/toast/MyToast";

const ProfilePage = () => {
    const {
        authTokens,
        loading,
        setLoading,
        setGeneralError,
        updateToken,
        generalError,
        closeAlert,
    } = useContext(AuthContext);

    const shouldLog = useRef(false);

    const [user, setUser] = useState("");
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userMiddleName, setUserMiddleName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");

    const [isEmailVerified, setEmailVerified] = useState("");
    const [isPhoneVerified, setPhoneVerified] = useState("");

    const [isEmailChanged, setEmailChanged] = useState(false);
    const [isEmailCodeSent, setEmailCodeSent] = useState(false);
    const [emailCode, setEmailCode] = useState("");
    const [confirmableDestination, setConfirmableDestination] = useState("");

    const [isMobileChanged, setMobileChanged] = useState(false);
    const [isMobileCodeSent, setMobileCodeSent] = useState(false);
    const [mobileCode, setMobileCode] = useState("");
    const [confirmableDestination2, setConfirmableDestination2] = useState("");

    useEffect(() => {
        document.title = "Профиль | " + ORGANIZATION_NAME;
    }, []);

    function refreshPage() {
        window.location.reload();
    }

    const getUser401 = async () => {
        await updateToken();
        getUser();
    };

    const patchUser401 = async () => {
        await updateToken();
        patchUser();
    };

    const getEmailVerificationCode401 = async () => {
        await updateToken();
        getEmailVerificationCode();
    };

    const sendEmailVerificationCode401 = async () => {
        await updateToken();
        sendEmailVerificationCode();
    };

    const sendMobileVerificationCode401 = async () => {
        await updateToken();
        sendMobileVerificationCode();
    };

    const getUser = async () => {
        setLoading(true);
        await axios({
            url: `${API_AUTH_URL}/users/${authTokens.user_data.pk}`,
            method: "GET",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
        })
            .then((res) => {
                console.log(res);
                setUser(res.data);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                if (e.response.status == 401) {
                    console.log("unathorized");
                    getUser401();
                } else if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.code) {
                    setGeneralError([e.response.status, e.response.data.code]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            });
    };

    const patchUser = async () => {
        setLoading(true);
        await axios({
            url: `${API_AUTH_URL}/users/${authTokens.user_data.pk}/`,
            method: "PATCH",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
            data: JSON.stringify({
                first_name: userFirstName,
                last_name: userLastName,
                middle_name: userMiddleName,
                email: userEmail,
                mobile: userPhone,
            }),
        })
            .then((res) => {
                console.log(res);
                setUser(res.data);
                setMobileChanged(false);
                setEmailChanged(false);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                if (e.response.status == 401) {
                    console.log("unathorized");
                    patchUser401();
                } else if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.email) {
                    setGeneralError(["", e.response.data.email]);
                } else if (e.response.data.mobile) {
                    setGeneralError(["", e.response.data.mobile]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            });
    };

    const getEmailVerificationCode = async () => {
        await axios({
            url: `${API_AUTH_URL}/verify/?destination=${userEmail.trim()}`,
            method: "GET",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
        })
            .then((res) => {
                console.log(res);
                setEmailCodeSent(true);
                setConfirmableDestination(userEmail.trim());
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                if (e.response.status == 401) {
                    console.log("unathorized");
                    getEmailVerificationCode401();
                } else if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.code) {
                    setGeneralError([e.response.status, e.response.data.code]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            });
    };

    const sendEmailVerificationCode = async () => {
        await axios({
            url: `${API_AUTH_URL}/verify/`,
            method: "POST",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
            data: JSON.stringify({
                destination: confirmableDestination,
                code: emailCode,
            }),
        })
            .then((res) => {
                console.log(res);
                setEmailCodeSent(false);
                setLoading(false);
                refreshPage();
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                if (e.response.status == 401) {
                    console.log("unathorized");
                    sendEmailVerificationCode401();
                } else if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.code) {
                    setGeneralError([e.response.status, e.response.data.code]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            });
    };

    const getMobileVerificationCode = async () => {
        await axios({
            url: `${API_AUTH_URL}/verify/?destination=${userPhone.trim()}`,
            method: "GET",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
        })
            .then((res) => {
                console.log(res);
                setMobileCodeSent(true);
                setConfirmableDestination2(userPhone.trim());
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                if (e.response.status == 401) {
                    console.log("unathorized");
                    updateToken();
                    getEmailVerificationCode();
                } else if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.code) {
                    setGeneralError([e.response.status, e.response.data.code]);
                } else if (e.response.data.message) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.message,
                    ]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            });
    };

    const sendMobileVerificationCode = async () => {
        await axios({
            url: `${API_AUTH_URL}/verify/`,
            method: "POST",
            headers: {
                "Accept-Language": "ru",
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access_token}`,
            },
            data: JSON.stringify({
                destination: confirmableDestination2,
                code: mobileCode,
            }),
        })
            .then((res) => {
                console.log(res);
                setMobileCodeSent(false);
                setLoading(false);
                refreshPage();
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                if (e.response.status == 401) {
                    console.log("unathorized");
                    sendMobileVerificationCode401();
                } else if (e.response.data.detail) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.detail,
                    ]);
                } else if (e.response.data.mobile) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.mobile,
                    ]);
                } else if (e.response.data.code) {
                    setGeneralError([e.response.status, e.response.data.code]);
                } else if (e.response.data[0]) {
                    setGeneralError([e.response.status, e.response.data[0]]);
                } else if (e.response.data.non_filed_errors) {
                    setGeneralError([
                        e.response.status,
                        e.response.data.non_filed_errors[0],
                    ]);
                }
            });
    };

    const handleUserData = () => {
        setUserFirstName(
            typeof user.first_name !== "undefined" ? user.first_name : ""
        );
        setUserLastName(
            typeof user.last_name !== "undefined" ? user.last_name : ""
        );
        setUserMiddleName(
            typeof user.middle_name === "undefined" || user.middle_name == null
                ? ""
                : user.middle_name
        );
        setUserEmail(typeof user.email !== "undefined" ? user.email : "");
        setUserPhone(
            typeof user.mobile === "undefined" || user.mobile == null
                ? ""
                : user.mobile
        );
        setPhoneVerified(user.mobile_verified);
        setEmailVerified(user.email_verified);
    };

    const patchUserClick = () => {
        if (
            userFirstName.trim() !== user.first_name ||
            userLastName.trim() !== user.last_name ||
            userMiddleName.trim() !== user.middle_name ||
            userEmail.trim() !== user.email ||
            userPhone.trim() !== user.mobile
        ) {
            patchUser();
        }
    };

    const handleEmailChange = () => {
        if (user.email === userEmail.trim()) {
            setEmailChanged(false);
        } else {
            setEmailChanged(true);
        }
    };

    const handleMobileChange = () => {
        if (user.mobile === userPhone) {
            setMobileChanged(false);
        } else {
            setMobileChanged(true);
        }
    };

    useEffect(() => {
        const body = document.getElementsByClassName("App")[0];
        body.classList.remove("lock");
        if (!shouldLog.current) {
            getUser();
        }
        return () => (shouldLog.current = true);
    }, []);

    useEffect(() => {
        handleUserData();
    }, [user]);

    useEffect(() => {
        handleEmailChange();
    }, [userEmail]);

    useEffect(() => {
        handleMobileChange();
    }, [userPhone]);

    return (
        <div>
            <MyToast error={generalError} closeAlert={closeAlert} />
            <Navbar />
            <div className="main">
                {loading ? (
                    <MyLoader />
                ) : (
                    <div
                        className="profile-block"
                        key={isEmailCodeSent || isMobileCodeSent}
                    >
                        <div className="profile-left-block profile-one-block">
                            <div className="one-thing-block">
                                <div className="one-thing-title">Фамилия*</div>
                                <MyInputText
                                    type="text"
                                    onChange={(e) =>
                                        setUserLastName(e.target.value)
                                    }
                                    value={userLastName}
                                />
                            </div>
                            <div className="one-thing-block">
                                <div className="one-thing-title">Имя*</div>
                                <MyInputText
                                    type="text"
                                    onChange={(e) =>
                                        setUserFirstName(e.target.value)
                                    }
                                    value={userFirstName}
                                />
                            </div>
                            <div className="one-thing-block">
                                <div className="one-thing-title">Отчество</div>
                                <MyInputText
                                    type="text"
                                    onChange={(e) =>
                                        setUserMiddleName(e.target.value)
                                    }
                                    value={userMiddleName}
                                />
                            </div>
                        </div>
                        <div className="profile-right-block profile-one-block">
                            <div className="one-thing-block annotation-profile-text">
                                <p>
                                    При изменении электронной почты и номера
                                    телефона рекомендуется незамедлительно
                                    подтвердить их, во избежание проблем с
                                    дальнейшим входом в аккаунт.
                                </p>
                                <p>
                                    Подтвердив номер телефона, вы получите
                                    возможность входа в аккаунт с помощью бота в
                                    Telegram (
                                    <a
                                        href="https://t.me/school_142_bot"
                                        className="bot-link"
                                        target="_blank"
                                    >
                                        @school_142_bot
                                    </a>
                                    ). Это наиболее удобный способ авторизации.
                                </p>
                            </div>
                            <div className="one-thing-block">
                                <div className="one-thing-title">
                                    Электронная почта*
                                </div>
                                <div className="email-input-block">
                                    <MyInputText
                                        type="text"
                                        onChange={(e) =>
                                            setUserEmail(e.target.value)
                                        }
                                        value={userEmail}
                                    />
                                    {!isEmailChanged && (
                                        <div>
                                            {isEmailVerified ? (
                                                <span className="tooltip">
                                                    <i className="material-icons email-phone-check color-good tooltip-toggle">
                                                        check
                                                    </i>
                                                    <span className="tooltip-text">
                                                        Email подтвержден
                                                    </span>
                                                </span>
                                            ) : (
                                                <span className="tooltip">
                                                    <i className="material-icons email-phone-check color-warning tooltip-toggle">
                                                        warning
                                                    </i>
                                                    <span className="tooltip-text">
                                                        Email не подтвержден
                                                    </span>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {!isEmailVerified && !isEmailCodeSent && (
                                    <div
                                        className="confirm-otp-text"
                                        onClick={() =>
                                            getEmailVerificationCode()
                                        }
                                    >
                                        Отправить код
                                    </div>
                                )}
                                {isEmailCodeSent && (
                                    <div
                                        style={{ display: "flex", gap: "10px" }}
                                    >
                                        <MyInputText
                                            onChange={(e) =>
                                                setEmailCode(e.target.value)
                                            }
                                            placeholder="Введите код"
                                            width="160px"
                                            text_align="center"
                                            font_size="18px"
                                            value={emailCode}
                                        />{" "}
                                        <div
                                            className="confirm-otp-text"
                                            onClick={() =>
                                                sendEmailVerificationCode()
                                            }
                                        >
                                            Подтвердить
                                        </div>{" "}
                                    </div>
                                )}
                            </div>
                            <div className="one-thing-block">
                                <div className="one-thing-title">
                                    Номер телефона
                                </div>
                                <div className="email-input-block">
                                    <MyInputText
                                        type="text"
                                        onChange={(e) =>
                                            setUserPhone(e.target.value)
                                        }
                                        value={userPhone}
                                    />
                                    {!isMobileChanged && (
                                        <div>
                                            {isPhoneVerified ? (
                                                <span className="tooltip">
                                                    <i className="material-icons email-phone-check color-good tooltip-toggle">
                                                        check
                                                    </i>
                                                    <span className="tooltip-text">
                                                        Номер подтвержден
                                                    </span>
                                                </span>
                                            ) : (
                                                <span className="tooltip">
                                                    <i className="material-icons email-phone-check color-warning tooltip-toggle">
                                                        warning
                                                    </i>
                                                    <span className="tooltip-text">
                                                        Номер не подтвержден
                                                    </span>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {!isPhoneVerified &&
                                    !isMobileCodeSent &&
                                    !isMobileChanged &&
                                    userPhone.length > 0 && (
                                        <div
                                            className="confirm-otp-text"
                                            onClick={() =>
                                                getMobileVerificationCode()
                                            }
                                        >
                                            Отправить код
                                        </div>
                                    )}
                                {isMobileCodeSent && (
                                    <div
                                        style={{ display: "flex", gap: "10px" }}
                                    >
                                        <MyInputText
                                            onChange={(e) =>
                                                setMobileCode(e.target.value)
                                            }
                                            placeholder="Введите код"
                                            width="160px"
                                            text_align="center"
                                            font_size="18px"
                                            value={mobileCode}
                                        />{" "}
                                        <div
                                            className="confirm-otp-text"
                                            onClick={() =>
                                                sendMobileVerificationCode()
                                            }
                                        >
                                            Подтвердить
                                        </div>{" "}
                                    </div>
                                )}
                            </div>
                        </div>
                        <MyButton
                            onClick={() => patchUserClick()}
                            disabled={
                                userEmail.length < 1 ||
                                userLastName.length < 1 ||
                                userFirstName.length < 1
                            }
                            style={{ marginTop: "20px" }}
                        >
                            Сохранить
                        </MyButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePage;
