import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import MySelect from "../UI/select/MySelect";
import MyLoader from "../UI/loader/MyLoader";
import MyInputText from "../UI/input/MyInputText";
import MyButton from "../UI/button/MyButton";
import { Data, DataTime } from "../../utils/Data";
import AuthContext from "../../context/AuthContext";

const FileChosing = ({ handleError, handleData }) => {
  const [fileList, setFileList] = useState([]);
  const [chosenFile, setChosenFile] = useState(1);
  const [chartDataTimeline, setChartDataTimeline] = useState(DataTime.data);
  const [chartDataSummary, setChartDataSummary] = useState(Data.data);
  const [timeInterval, setTimeInterval] = useState(60.0);

  const {
    authTokens,
    loading,
    setLoading,
    setGeneralError,
    updateToken,
    generalError,
    closeAlert,
    isRefreshingToken,
    setIsRefreshingToken,
} = useContext(AuthContext);

  const getFileList = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${API_URL}/files/`,
        params: {
          "for_stats": true,
        },
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      setFileList(response.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                await getFileList();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data[0]) {
                setGeneralError([e.response.status, e.response.data[0]]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        setLoading(false);
    }
  };

  const getCharts = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${API_URL}/statistics/`,
        params: {
          filetype: "timeline",
          file: chosenFile,
        },
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      console.log(response.data);
      setChartDataTimeline(response.data.timeline);
      setChartDataSummary(response.data.bars);
      setLoading(false);
    } catch (e) {
      console.log(e);
            if (e.response.status === 401 && !isRefreshingToken) {
                setIsRefreshingToken(true); // Set flag to prevent recursion
                await updateToken();
                setIsRefreshingToken(false); // Reset flag after refresh
                getCharts();
            } else if (e.response.data.detail) {
                setGeneralError([e.response.status, e.response.data.detail]);
            } else if (e.response.data[0]) {
                setGeneralError([e.response.status, e.response.data[0]]);
            } else if (e.response.data.code) {
                setGeneralError([e.response.status, e.response.data.code]);
            } else if (e.response.data.non_filed_errors) {
                setGeneralError([
                    e.response.status,
                    e.response.data.non_filed_errors[0],
                ]);
            }
        setLoading(false);
    }
  };


  const getExcel = async () => {
    try {
      const response = await axios({
        method: "GET",
        responseType: "blob",
        url: `${API_URL}/statistics/`,
        params: {
          filetype: "excel",
          file: chosenFile,
        },
        headers: {
          "content-type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      setLoading(false);
    } catch (e) {
      if (e.response.status === 401 && !isRefreshingToken) {
        setIsRefreshingToken(true); // Set flag to prevent recursion
        await updateToken();
        setIsRefreshingToken(false); // Reset flag after refresh
        await getExcel();
    } else if (e.response.data.detail) {
        setGeneralError([e.response.status, e.response.data.detail]);
    } else if (e.response.data[0]) {
        setGeneralError([e.response.status, e.response.data[0]]);
    } else if (e.response.data.code) {
        setGeneralError([e.response.status, e.response.data.code]);
    } else if (e.response.data.non_filed_errors) {
        setGeneralError([
            e.response.status,
            e.response.data.non_filed_errors[0],
        ]);
    }
    setLoading(false);
    }
  };

  const getCsv = async () => {
    try {
      const response = await axios({
        method: "GET",
        responseType: "blob",
        url: `${API_URL}/statistics/`,
        params: {
          filetype: "csv",
          file: chosenFile,
        },
        headers: {
          "content-type": "application/vnd.ms-excel",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      console.log(response);

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "file.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      setLoading(false);
    } catch (e) {
      if (e.response.status === 401 && !isRefreshingToken) {
        setIsRefreshingToken(true); // Set flag to prevent recursion
        await updateToken();
        setIsRefreshingToken(false); // Reset flag after refresh
        await getCsv();
    } else if (e.response.data.detail) {
        setGeneralError([e.response.status, e.response.data.detail]);
    } else if (e.response.data[0]) {
        setGeneralError([e.response.status, e.response.data[0]]);
    } else if (e.response.data.code) {
        setGeneralError([e.response.status, e.response.data.code]);
    } else if (e.response.data.non_filed_errors) {
        setGeneralError([
            e.response.status,
            e.response.data.non_filed_errors[0],
        ]);
    }
setLoading(false);
    }
  };

  const getNewSummary = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${API_URL}/request_summary/`,
        data: {
          file_pk: chosenFile,
          interval: timeInterval
        },
        headers: {
          "Content-type": "application/json",
          "Accept-Language": "ru",
          Authorization: `Bearer ${authTokens.access_token}`,
        },
      });
      console.log(response);
      setGeneralError(['', 'Статистика успешно пересчитана'])
      setLoading(false);
    } catch (e) {
      if (e.response.status === 401 && !isRefreshingToken) {
        setIsRefreshingToken(true); // Set flag to prevent recursion
        await updateToken();
        setIsRefreshingToken(false); // Reset flag after refresh
        await getNewSummary();
    } else if (e.response.data.detail) {
        setGeneralError([e.response.status, e.response.data.detail]);
    } else if (e.response.data[0]) {
        setGeneralError([e.response.status, e.response.data[0]]);
    } else if (e.response.data.code) {
        setGeneralError([e.response.status, e.response.data.code]);
    } else if (e.response.data.non_filed_errors) {
        setGeneralError([
            e.response.status,
            e.response.data.non_filed_errors[0],
        ]);
    }
setLoading(false);
    }
  };

  useEffect(() => {
    getFileList();
  }, []);

  useEffect(() => {
    handleData([chosenFile, chartDataSummary, chartDataTimeline]);
  }, [chosenFile, chartDataSummary, chartDataTimeline]);

  const getTwoCharts = () => {
    getCharts();
  };

  return (
    <div className="application-useful-block">
      <h1>Выбор файла для запроса статистики</h1>
      <div className="file-filter-form">
        <p>
          Установить желаемые фильтры и выберите файл из предложенных. Если
          вашего файла здесь нет, то его анализ ещё не закончен. Проверьте его
          статус в личном кабинете или обратитесь за помощью к администратору.
        </p>
        <MySelect
          value={chosenFile}
          onChange={(e) => setChosenFile(e)}
          defaultValue={"Выберите файл"}
          options={fileList}
        />
      </div>
      <div className="three-buttons-block">
        <MyButton onClick={getExcel}>Загрузить Excel</MyButton>
        <MyButton onClick={getCsv}>Загрузить CSV</MyButton>
        <MyButton onClick={getTwoCharts}>Построить графики</MyButton>
        <h2>Пересчёт статистики</h2>
        <p>
          Укажите интервал для анализа в секундах. Пересчёт будет произведён для выбранного
          файла и может занять некоторое время.
        </p>
        <MyInputText
          onChange={(e) => setTimeInterval(e.target.value)}
          type='text'
        ></MyInputText>
        <MyButton onClick={getNewSummary}>Пересчитать</MyButton>
      </div>
    </div>
  );
};

export default FileChosing;
